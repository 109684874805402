import React from "react";
import PageBase from "./pageBase";
import { 
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import migrationsLogo from "./../images/Migrations_SymbolOnly_Green_RGB.svg"

const CAREERS_TITLE = "JOIN MIGRATIONS.ML";

const CAREERS_STATEMENT_1 = "Migrations.ml is an early phase start-up with a focus on delivering value to the wholesale fixed income market. We're a small company located in Toronto's financial district and are committed to providing an environment in which talented people can take the next step of their careers.";
const NO_JOBS_STATEMENT = "All of our positions are currently filled but if you have any exciting ideas to share, we'd love to hear them!"
const SEND_EMAIL_part1 = "Send an email to ";
const SEND_EMAIL_part2 = " with your resume attached and we'll get in touch!";
const EMAIL = "INFO@MIGRATIONS.ML"

const useStyles = makeStyles({
  root: {
    marginTop: "5em",
    textAlign: "center",
  },
  img: { margin: "2em 0" },
  title: { marginBottom: "2em" },
  description: { marginBottom: "2em" },
  blurbBox: {
    backgroundColor: "#d9d9d6",
    filter: "drop-shadow(0px 4px 20px rgb(10, 19, 31))",
    padding: "4rem 3rem 4rem 3rem",
    // color: "rgba(32, 53, 80, 0.62)",
    color: "black",
    // fontWeight: "bolder",
  },
  email: {
    fontSize: ""
  },
}); 

const CareersPage = () => {
  const classes = useStyles();
  return (
    <PageBase>
      <Container className={classes.root}>
        <Grid container direction="column">
          <Grid className={classes.img}>
            <img height={80} src={migrationsLogo} alt="Migrations.ML"/>
          </Grid>
          <Grid className={classes.title}>
            <Typography variant="h4">
              {CAREERS_TITLE}
            </Typography>
          </Grid>
          <Grid className={classes.description}>
            <Typography style={{fontSize: "14px"}}>
              {CAREERS_STATEMENT_1}
            </Typography>
          </Grid>
        </Grid>
        <Paper className={classes.blurbBox}>
          <Typography variant="h6">
            {NO_JOBS_STATEMENT}
          </Typography>
          <Typography style={{fontSize: "14px"}}>
            {SEND_EMAIL_part1}
            <span className={classes.email}>{EMAIL}</span>
            {SEND_EMAIL_part2}
          </Typography>
        </Paper>
      </Container>
    </PageBase>
  );
};

export default CareersPage;
